"use client";

import clsx from "clsx";
import { useSelectedLayoutSegment } from "next/navigation";
import { ComponentProps } from "react";
import type { AppPathnames } from "@/config";
import { Link } from "@/navigation";
import { ComponentPropsWithoutRef } from "react";

interface NavigationLinkProps<Pathname extends AppPathnames> extends ComponentPropsWithoutRef<"a"> {
    href: Pathname;
    external?: boolean;
}

export default function NavigationLink<Pathname extends AppPathnames>({ href, external = false, ...rest }: NavigationLinkProps<Pathname>) {
    const selectedLayoutSegment = useSelectedLayoutSegment();
    const pathname = selectedLayoutSegment ? `/${selectedLayoutSegment}` : "/";
    const isActive = pathname === href;

    if (external) {
        return (
            <a
                aria-current={isActive ? "page" : undefined}
                className={clsx("inline-block transition-colors w-full", isActive ? "text-white" : "text-gray-400 hover:text-gray-200")}
                href={href}
                {...rest}
            />
        );
    }

    return (
        <Link
            aria-current={isActive ? "page" : undefined}
            className={clsx("inline-block transition-colors w-full", isActive ? "text-white" : "text-gray-400 hover:text-gray-200")}
            href={href as any}
            {...rest}
        />
    );
}
