'use client';
import { Fragment, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import {
	ChevronDownIcon,
	PhoneIcon,
	PlayCircleIcon,
	ChevronRightIcon,
	FlagIcon,
	UserIcon
} from '@heroicons/react/20/solid';
import Image from 'next/image';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import logo from '@public/img/landing/LN_LOGO_HEADER.webp';
import LocaleSwitcher from '@/components/global/locale/LocaleSwitcher';
import NavigationLink from '@/components/global/NavigationLink';

type TPopover = {
	name: string;
	description: string;
	href: string;
	icon: any;
	target?: string;
}[];
interface INav {
	name: string;
	href: string;
	hasPopover: boolean;
	popover?: () => TPopover;
	target?: string;
}

const infos = (): TPopover => [
	// rules
	{
		name: 'Discord',
		description: 'Join our Discord',
		href: 'https://discord.gg/luckynetwork',
		icon: PlayCircleIcon,
		target: '_blank'
	},
	{ name: 'Rules', description: 'Read our rules', href: '/rules', icon: FlagIcon },
	{ name: 'Staff', description: 'Meet our staff', href: '/staff', icon: UserIcon },
	{
		name: 'Support',
		description: 'Contact our support',
		href: 'https://luckynet.work/support ',
		icon: PhoneIcon
	},
	{ name: 'Event UHC', description: 'Contact our support', href: '/event ', icon: PhoneIcon }
];

export default function Header() {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const pathName = usePathname();

	const navigation = [
		{
			name: 'Home',
			href: `/`,
			hasPopover: false
		},
		// { name: "Famous", href: "/famous", hasPopover: false },
		{
			name: 'Rules',
			href: '/rules',
			hasPopover: false
		},

		{ name: 'Store', href: 'https://store.luckynetwork.net', hasPopover: false, target: '_blank' },
		{ name: 'Discord', href: 'https://discord.gg/luckynetwork', hasPopover: false, target: '_blank' },
		{ name: 'Staff', href: '/staff', hasPopover: false },
		{ name: 'Apply', href: '/apply', hasPopover: false }
		// { name: "Event UHC", href: "/event-uhc", hasPopover: false },
		// { name: "Info", href: "#", hasPopover: true, popover: infos },
	];

	return (
		<header className="fixed top-5 left-0 right-0 bg-header rounded-md mx-5 sm:mx-10 md:mx-15 lg:mx-20 z-10">
			<Popover
				className="flex items-center justify-between lg:justify-end"
				aria-label="Global"
			>
				{/* Logo  */}
				<div
					className="flex"
					dir="ltr"
				>
					<NavigationLink
						href="/"
						className="bg-primary rounded-tl-md rounded-bl-md"
					>
						<Image
							src={logo}
							alt="LN Logo"
							width={150}
							height={150}
						/>
					</NavigationLink>
				</div>
				{/* End Logo */}

				{/* Mobile button */}
				<div className="flex lg:hidden px-5 text-white">
					<Popover.Button
						onClick={() => setSidebarOpen(true)}
						className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-white"
					>
						<span className="sr-only">Open main menu</span>
						<Bars3Icon
							className="h-6 w-6"
							aria-hidden="true"
						/>
					</Popover.Button>
				</div>
				{/* End Mobile button */}

				{/* Desktop Navigation */}
				<Popover.Group className="hidden lg:flex lg:gap-x-[50px] mx-20 font-nexabold">
					{navigation.map(
						(item: {
							[x: string]: any;
							name: string;
							href: string;
							hasPopover: boolean;
							target?: string;
						}) => (
							<Fragment key={item.name}>
								{item.hasPopover ? (
									<Popover className="relative">
										<Popover.Button className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-white">
											{
												item.name
											}
											<ChevronDownIcon
												className="h-5 w-5 flex-none text-gray-400"
												aria-hidden="true"
											/>
										</Popover.Button>

										<Transition
											as={
												Fragment
											}
											enter="transition ease-out duration-200"
											enterFrom="opacity-0 translate-y-1"
											enterTo="opacity-100 translate-y-0"
											leave="transition ease-in duration-150"
											leaveFrom="opacity-100 translate-y-0"
											leaveTo="opacity-0 translate-y-1"
										>
											<Popover.Panel className="absolute -left-8 rounded-lg top-[40px] z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-dropdown shadow-lg ring-1 ring-gray-900/5">
												<div className="p-4">
													{item
														.popover()
														.map(
															(item: {
																[
																	x: string
																]: any;
																name: string;
																description: string;
																href: string;
																icon: any;
																target: any;
															}) => (
																<NavigationLink
																	href={
																		item.href as any
																	}
																	external={
																		item.target ===
																		'_blank'
																	}
																	key={
																		item.name
																	}
																	target={
																		item.target
																	}
																>
																	<div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 hover:text-black">
																		<item.icon
																			className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
																			aria-hidden="true"
																		/>
																		<div className="flex-1">
																			<p className="font-semibold text-gray-400">
																				{
																					item.name
																				}
																			</p>
																			<p className="text-gray-500">
																				{
																					item.description
																				}
																			</p>
																		</div>
																		<ChevronRightIcon
																			className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
																			aria-hidden="true"
																		/>
																	</div>
																</NavigationLink>
															)
														)}
												</div>
											</Popover.Panel>
										</Transition>
									</Popover>
								) : (
									<NavigationLink
										href={
											item.href as any
										}
										target={item.target}
										className="text-sm font-semibold leading-6 text-white"
									>
										{item.name}
									</NavigationLink>
								)}
							</Fragment>
						)
					)}
				</Popover.Group>
				{/* End Desktop Navigation */}

				{/* Start Mobile */}
				<Transition
					show={sidebarOpen}
					as={Fragment}
					enter="duration-200 ease-out"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="duration-100 ease-in"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95"
				>
					<Popover.Panel
						static
						focus
						className={`absolute inset-x-0 rounded-md -top-1 transform transition lg:hidden `}
					>
						<div className="rounded-md bg-gray-800 shadow-1g ring-4 ring-white ring-opacity-5 divide-y-2 divide-gray-50">
							<div className="px-5 py-5">
								<div className="flex items-center justify-between">
									<div className="max-w-sm">
										<div
											className="flex"
											dir="ltr"
										>
											<NavigationLink
												href="/"
												className="bg-primary rounded-tl-md rounded-bl-md"
											>
												<Image
													src={
														logo
													}
													alt="LN Logo"
													width={
														150
													}
													height={
														150
													}
												/>
											</NavigationLink>
										</div>
									</div>
									<div className="-mr-2">
										<Popover.Button
											onClick={() =>
												setSidebarOpen(
													false
												)
											}
											className="inline-flex items-center justify-center rounded-md bg-white p-2  hover:bg-gray-100 hover: text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-makertech-lightblue"
										>
											<span className="sr-only">
												Close
												menu
											</span>
											<XMarkIcon
												className="h-6 w-6"
												ario-hidden="true"
											/>
										</Popover.Button>
									</div>
								</div>
								{/* Mobile Navigation */}
								<div className="mt-6">
									<nav className="grid gap-y-3  space-y-2">
										{navigation.map(
											(item: {
												[
													x: string
												]: any;
												name: string;
												href: string;
												hasPopover: boolean;
												target?: string;
											}) => (
												<Fragment
													key={
														item.name
													}
												>
													{item.hasPopover ? (
														<Popover className="relative">
															<Popover.Button className="flex justify-between shrink-0 flex-1 w-[100%] gap-x-1 border-2 px-3 py-2 rounded-sm text-sm font-semibold leading-6 text-white">
																{
																	item.name
																}
																<ChevronDownIcon
																	className="h-5 w-5 flex-none text-gray-400"
																	aria-hidden="true"
																/>
															</Popover.Button>

															<Transition
																as={
																	Fragment
																}
																enter="transition ease-out duration-200"
																enterFrom="opacity-0 translate-y-1"
																enterTo="opacity-100 translate-y-0"
																leave="transition ease-in duration-150"
																leaveFrom="opacity-100 translate-y-0"
																leaveTo="opacity-0 translate-y-1"
															>
																<Popover.Panel className="absolute -left-8 rounded-lg top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl  bg-zinc-800 shadow-lg ring-1 ring-gray-900/5">
																	<div className="p-4">
																		{item
																			.popover()
																			.map(
																				(item: {
																					[
																						x: string
																					]: any;
																					name: string;
																					description: string;
																					href: string;
																					icon: any;
																				}) => (
																					<NavigationLink
																						href={
																							item.href as any
																						}
																						onClick={() =>
																							setSidebarOpen(
																								false
																							)
																						}
																						key={
																							item.name
																						}
																						target={
																							item.target
																						}
																					>
																						<div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50 hover:text-black">
																							<item.icon
																								className="flex-shrink-0 h-6 w-6 text-gray-400 group-hover:text-gray-500"
																								aria-hidden="true"
																							/>
																							<div className="flex-1">
																								<p className="font-semibold text-gray-400">
																									{
																										item.name
																									}
																								</p>
																								<p className="text-gray-500">
																									{
																										item.description
																									}
																								</p>
																							</div>
																							<ChevronRightIcon
																								className="h-5 w-5 text-gray-400 group-hover:text-gray-500"
																								aria-hidden="true"
																							/>
																						</div>
																					</NavigationLink>
																				)
																			)}
																	</div>
																</Popover.Panel>
															</Transition>
														</Popover>
													) : (
														<NavigationLink
															href={
																item.href as any
															}
															onClick={() =>
																setSidebarOpen(
																	false
																)
															}
															target={
																item.target
															}
															className="text-sm py-2 border-2 px-3 rounded-sm font-semibold leading-6 text-white"
														>
															{
																item.name
															}
														</NavigationLink>
													)}
												</Fragment>
											)
										)}
									</nav>
								</div>

								{/* <LocaleSwitcher /> */}
								<div className="mt-6 flex flex-wrap items-center gap-2">
									<NavigationLink
										href={
											'https://support.luckynetwork.net/' as any
										}
										target="_blank"
										className="flex items-center bg-primary text-blackln font-nexabold rounded-sm px-5 py-3"
									>
										Support
									</NavigationLink>
								</div>
							</div>
						</div>
					</Popover.Panel>
				</Transition>
				{/* End mobile */}

				<div className="hidden lg:flex lg:flex-1 lg:justify-end lg:px-4">
					<NavigationLink
						href={'https://support.luckynetwork.net/' as any}
						target="_blank"
						className="flex items-center bg-primary text-blackln font-nexabold rounded-sm px-5 py-3"
					>
						Support
					</NavigationLink>
					{/* <LocaleSwitcher /> */}
					{/* <Link
                        href={"/apply"}
                        className="flex items-center bg-primary text-blackln font-nexabold rounded-sm px-5 py-2"
                    >
                        Apply
                    </Link> */}
				</div>
			</Popover>
		</header>
	);
}
